import { useVfm } from 'vue-final-modal'

const useSearchNavigation = () => {
  const vfm = useVfm()
  const { close, closeAll } = vfm

  const focusOnSearchInput = () => {
    const search = document.getElementById('search-field')
    if (search) {
      search.focus()
      setTimeout(() => {
        search.focus()
      }, 100)
    }
  }

  const currentSelectedButton = ref<number>(-1)
  const scrollview = ref<HTMLDivElement | null>(null)

  function handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'ArrowDown' || event.code === 'Tab') {
      event.preventDefault()
      selectNextButton('DOWN')
    } else if (event.code === 'ArrowUp') {
      event.preventDefault()
      selectNextButton('UP')
    } else if (event.code === 'Enter') {
      event.preventDefault()
      if (currentSelectedButton.value !== -1) {
        const link = document.querySelectorAll(
          '.current-selection',
        ) as NodeListOf<HTMLElement>
        if (link.length > 0) {
          link[0].click()
        }
      }
    }
  }

  const toggleLinkStyle = (link: HTMLElement, style: 'ADD' | 'REMOVE') => {
    if (!link) return

    if (style === 'ADD') {
      link.classList.add(
        'bg-primary-500',
        'dark:bg-primary-500',
        'current-selection',
      )
    } else {
      link.classList.remove(
        'bg-primary-500',
        'dark:bg-primary-500',
        'current-selection',
      )
    }
  }

  const selectNextButton = (moveTo: 'UP' | 'DOWN' | 'FIRST' | 'NONE') => {
    // get all links
    const links = document.querySelectorAll(
      '.search-link',
    ) as NodeListOf<HTMLElement>

    if (!links.length) return

    // Toggle the button style
    const setSelectedButtonStyle = (link: HTMLElement) => {
      currentSelectedButton.value = link.tabIndex
      links.forEach((lnk) => {
        const isSelected: boolean = lnk.tabIndex === currentSelectedButton.value
        const linkStyle = isSelected ? 'ADD' : 'REMOVE'
        toggleLinkStyle(lnk, linkStyle)
        if (isSelected) {
          updateLinkPosition(lnk)
        }
      })
    }
    // Current button index
    const currentIndex = () =>
      Array.from(links).findIndex(
        (link) => link.tabIndex === currentSelectedButton.value,
      )

    if (moveTo === 'FIRST') {
      currentSelectedButton.value = 0
      const btn = links.item(currentSelectedButton.value)
      setSelectedButtonStyle(btn)
    } else if (moveTo === 'NONE') {
      const btn = links.item(currentSelectedButton.value!)
      toggleLinkStyle(btn, 'REMOVE')
      currentSelectedButton.value = -1
    } else if (moveTo === 'DOWN') {
      const btn = links.item((currentIndex() + 1) % links.length)
      setSelectedButtonStyle(btn)
    } else if (moveTo === 'UP') {
      const btn = links.item((currentIndex() - 1 + links.length) % links.length)
      setSelectedButtonStyle(btn)
    }
  }

  function updateLinkPosition(linkEl: HTMLElement) {
    if (!scrollview.value) return
    const rect = linkEl.getBoundingClientRect()
    const scrollViewRect = scrollview.value.getBoundingClientRect()

    // Check if the link is not fully visible
    if (rect.bottom < scrollViewRect.top || rect.top > scrollViewRect.bottom) {
      // Scroll to the element
      scrollview.value.scrollTo({
        top: linkEl.offsetTop - scrollview.value.offsetTop,
        behavior: 'auto',
      })
    }
  }

  onMounted(() => {
    focusOnSearchInput()
    window.addEventListener('keydown', handleKeyDown, false)
  })

  onUnmounted(() => {
    window.addEventListener('keydown', handleKeyDown, false)
  })

  // close modal on page change
  useRouter().beforeEach(() => {
    closeAll()
  })

  return {
    currentSelectedButton,
    scrollview,
    selectNextButton,
    focusOnSearchInput,
  }
}

export { useSearchNavigation }
