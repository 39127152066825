<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import type { ICompanies } from '~/composables/useAPI/types'

const term = ref<string>('')

const { companies, createDivisionURL } = useDivisions()

const { selectNextButton, scrollview, focusOnSearchInput } =
  useSearchNavigation()

const query = ref<ICompanies[]>()

const result = computed(() => {
  if (term.value === '') {
    return companies.value
  } else {
    return query.value
  }
})

const clearSearch = () => {
  term.value = ''
  focusOnSearchInput()
}

const onSearchChange = (term: string) => {
  // query.value = search(term)

  // reset the current selected button
  selectNextButton('NONE')
}
</script>
<template>
  <VueFinalModal
    :z-index-fn="() => 100000"
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="flex flex-col mt-[70px] max-w-3xl w-full mx-4 p-4 bg-gray-200 dark:bg-[#1B1F29] border dark:border-gray-700 rounded-lg space-y-2"
  >
    <h1 class="text-xl">Search for leases</h1>
    <FormKit
      id="search-field"
      ref="search"
      v-model="term"
      type="text"
      class="bg-white"
      prefix-icon-class="absolute top-2 ml-2 flex w-5 items-center"
      prefix-icon="search"
      :delay="400"
      @input="onSearchChange"
    />

    <div
      ref="scrollview"
      class="scrollview flex max-h-[50vh] flex-col overflow-y-scroll scroll-smooth"
    >
      <div v-if="store.companies" class="flex flex-col gap-4">
        <span v-if="term" class="flex gap-2 text-sm">
          <span>
            {{
              result?.length === 0
                ? 'Looks like our search engine needs a real estate agent! No results found for '
                : 'Searching for: '
            }}
          </span>
          <span class="rounded-lg bg-gray-700 px-2">
            {{ term }}
            <Icon
              name="close"
              filled
              class="mb-1 cursor-pointer"
              @click="clearSearch()"
            />
          </span>
        </span>
        <div class="mt-4 flex flex-col gap-4">
          <NuxtLink
            v-for="(company, index) in result"
            :key="company.id + term"
            :tabindex="index"
            :to="createDivisionURL(company.id)"
            class="search-link hover:bg-primary dark:hover:bg-primary-500 cursor-pointer rounded-lg bg-gray-300 px-4 py-3 dark:bg-gray-800"
          >
            <span class="flex gap-2">
              <span class="self-center">
                <span
                  class="mask mask-squircle flex w-6 overflow-hidden rounded-md bg-red-500"
                >
                  <Icon
                    :name="`flags-1x1/${company.countryId?.toLowerCase()}`"
                    size="24"
                    filled
                  />
                </span>
              </span>
              <span class="flex w-full flex-col">
                <span class="w-full font-semibold">
                  {{ company.name }}
                </span>
                <span
                  v-if="company.tradingName"
                  class="w-full text-sm text-gray-400"
                >
                  {{ company.tradingName }}
                </span>
              </span>
            </span>
          </NuxtLink>
        </div>
      </div>
      <div v-else>Loading companies</div>
    </div>
  </VueFinalModal>
</template>
